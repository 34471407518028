import clsx from 'clsx';
import React, { HTMLProps } from 'react';

export interface TextInputProps extends HTMLProps<HTMLInputElement> {
  error?: string;
  theme?: 'standard' | 'gray';
  'data-testid'?: string;
  icon?: React.ReactNode;
}

export default function TextInput(props: TextInputProps): JSX.Element {
  const { error, theme = 'standard', type = 'text', icon, placeholder, value } = props;

  const hasError = Boolean(error?.length);

  return (
    <div className="w-full relative">
      <input
        {...props}
        type={type}
        value={value}
        data-testid={props['data-testid'] ?? 'text-input'}
        placeholder={placeholder}
        className={clsx(
          'pl-5 h-12 w-full flex items-center text-base font-semibold rounded-lg border-2 border-solid outline-none transition-colors duration-200 ease-in-out',
          {
            'text-galaxy-800 bg-white focus:bg-white border-galaxy-200 focus:border-meteor placeholder:text-galaxy-500':
              !hasError && theme === 'standard',
            'text-galaxy-800 bg-galaxy-100 focus:bg-white border-galaxy-100 focus:border-meteor placeholder:text-galaxy-500':
              !hasError && theme === 'gray',
            'text-mars bg-red-100 focus:bg-red-100 border-red-500 focus:border-red-500': hasError,
            'pr-10': icon,
            'pr-5': !icon
          }
        )}
      />
      {icon && (
        <div className="absolute top-0 bottom-0 right-0 w-16 h-full flex items-center justify-center">
          <div className="w-6 h-6">{icon}</div>
        </div>
      )}
    </div>
  );
}
