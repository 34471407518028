import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import routes from 'lib/utils/routes';
import { SignUp, VerifyEmail, CreateAccount, ThankYou } from 'pages';
import { initializePendo } from 'lib/thirdParty';

function App(): JSX.Element {
  initializePendo();

  return (
    <Routes>
      <Route path={routes.account.getStarted} element={<SignUp />} />
      <Route path={routes.account.finalize} element={<CreateAccount />} />
      <Route path={routes.account.verify} element={<VerifyEmail />} />
      <Route path={routes.account.thankYou} element={<ThankYou />} />
      <Route path="*" element={<Navigate to={routes.account.getStarted} replace />} />
    </Routes>
  );
}

export default App;
