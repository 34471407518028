import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import * as resources from './locales';

const fallbackLng = 'en';

const options = {
  order: ['navigator'],
  excludeCacheFor: ['cimode']
};

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng,
    supportedLngs: Object.keys(resources),
    load: 'languageOnly',
    detection: options,
    ns: Object.keys(resources[fallbackLng]),
    defaultNS: 'common',
    nsSeparator: ':',
    keySeparator: '.',
    backend: {
      loadPath: './locales/{{lng}}/{{ns}}.json'
    },
    interpolation: {
      escapeValue: true,
      formatSeparator: ','
    },
    react: {
      useSuspense: true
    },
    debug: false
  });

export default i18n;
