import React from 'react';
import './Select.scss';
import Select, { ActionMeta, SingleValue } from 'react-select';

export interface OptionType {
  value: string;
  label: string;
}

export interface SelectProps {
  id?: string;
  inputId?: string;
  name: string;
  value?: SingleValue<OptionType>;
  placeholder?: React.ReactNode;
  onChange: (newValue: SingleValue<OptionType>, actionMeta: ActionMeta<OptionType>) => void;
  options: OptionType[];
}

export default function SelectInput(props: SelectProps) {
  const { id, inputId, name, placeholder, value, options, onChange } = props;

  return (
    <Select
      id={id}
      name={name}
      inputId={inputId}
      data-testid={inputId}
      value={value}
      isMulti={false}
      options={options}
      onChange={onChange}
      placeholder={placeholder}
      isSearchable
      isClearable={false}
      className="react-select-container"
      classNamePrefix="react-select"
    />
  );
}
