import { BASE_URL } from './constants';
import {
  CreateAccountApiPayload,
  ResendVerificationEmailApiPayload,
  VerifyAccountApiPayload
} from 'types/Account';
import axios from 'axios';

export const createAccount = async (payload: CreateAccountApiPayload) => {
  return await axios.post(`${BASE_URL}/sign-up`, payload);
};

export const verifyAccount = async (payload: VerifyAccountApiPayload) => {
  try {
    return await axios.post(`${BASE_URL}/user/verify`, payload);
  } catch (e) {
    console.error(e);
  }
};

export const resendVerificationEmail = async (payload: ResendVerificationEmailApiPayload) => {
  try {
    return await axios.post(`${BASE_URL}/user/resend-verification`, payload);
  } catch (e) {
    console.error(e);
  }
};

export const resendInvitationEmail = async (email: string) => {
  try {
    return await axios.post(`${BASE_URL}/user/send-invitation-email`, email, {
      headers: {
        'Content-Type': 'text/plain'
      }
    });
  } catch (e) {
    console.error(e);
  }
};
