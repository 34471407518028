import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from 'app/store';

// Define a type for the slice state
interface InitialAccountState {
  email: string;
  companyName: string;
  addressLineOne: string;
  password: string;
  city: string;
  state: string;
  postalCode: string;
  country: string;
  numVehicles: string;
  numDrivers: string;
  givenName: string;
  familyName: string;
}

// Define the initial state using that type
const initialState: InitialAccountState = {
  email: '',
  password: '',
  companyName: '',
  addressLineOne: '',
  city: '',
  state: '',
  postalCode: '',
  country: '',
  numVehicles: '',
  numDrivers: '',
  givenName: '',
  familyName: ''
};

export const accountSlice = createSlice({
  name: 'account',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    setAccountData: (state, action: PayloadAction<Partial<InitialAccountState>>) => {
      return { ...state, ...action.payload };
    }
  }
});

export const { setAccountData } = accountSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const selectAccount = (state: RootState) => state.account;

export default accountSlice.reducer;
