import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

export default function Footer() {
  const { t } = useTranslation('common');

  const wiseSystemsEmail = 'feedback@wisesystems.com';

  return (
    <div className="py-8 flex items-center justify-center">
      <span className="text-sm text-center text-galaxy-500" data-testid="footer-text">
        <Trans
          i18nKey={t('common:footer')}
          values={{
            email: wiseSystemsEmail
          }}
          components={{
            a: (
              <a
                data-testid="footer-help-email-link"
                href={`mailto:${wiseSystemsEmail}`}
                className="text-meteor underline cursor-pointer"
              />
            )
          }}
        />
      </span>
    </div>
  );
}
