import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { TextInput, PasswordInput, Button, Select } from 'components';
import { AccountCreationLayout } from 'layouts';
import { setAccountData } from 'app/slices/account/accountSlice';
import routes from 'lib/utils/routes';

import { Formik, FormikProps } from 'formik';
import * as Yup from 'yup';

interface InitialValues {
  givenName: string;
  familyName: string;
  password: string;
  email: string;
  country: string;
}

const MIN_PASSWORD_LENGTH = 8;
const PASSWORD_REGEX = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/;

export default function SignUp() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation(['signUp', 'common']);

  const msgRequired = t('common:input.validation.required');
  const msgEmailInvalid = t('common:input.validation.email.invalid');
  const msgPasswordMinLength = t('common:input.validation.password.minLength');
  const msgPasswordPattern = t('common:input.validation.password.pattern');

  const SignupSchema = Yup.object().shape({
    givenName: Yup.string().required(msgRequired),
    familyName: Yup.string().required(msgRequired),
    password: Yup.string()
      .min(MIN_PASSWORD_LENGTH, msgPasswordMinLength)
      .matches(PASSWORD_REGEX, msgPasswordPattern)
      .required(msgRequired),
    email: Yup.string().email(msgEmailInvalid).required(msgRequired),
    country: Yup.string().required(msgRequired)
  });

  const initialValues = { email: '', password: '', givenName: '', familyName: '', country: '' };

  // TODO: 11/9/2022 Internationalize strings below
  const countryOptions = [
    {
      label: 'United States',
      value: 'USA'
    },
    {
      label: 'Canada',
      value: 'CAN'
    }
  ];

  return (
    <AccountCreationLayout>
      <div className="w-full flex flex-col gap-5">
        <div className="flex flex-col gap-2">
          <h1
            data-testid="signup-heading"
            className="text-2xl md:text-3xl text-galaxy-800 font-bold"
          >
            {t('signUp:heading')}
          </h1>
          <p className="self-start text-galaxy-500 font-medium text-base">
            {t('signUp:subHeading')}
          </p>
        </div>
        <div className="w-full flex flex-col0">
          <Formik
            initialValues={initialValues}
            validationSchema={SignupSchema}
            onSubmit={(values, { setSubmitting }) => {
              dispatch(setAccountData(values));
              setSubmitting(false);
              navigate(routes.account.finalize);
            }}
          >
            {(props: FormikProps<InitialValues>) => {
              const {
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                setFieldValue
              } = props;

              return (
                <form className="w-full flex flex-col gap-4" onSubmit={handleSubmit}>
                  <div className="flex gap-4 md:gap-7 flex-col md:flex-row">
                    <div className="flex flex-col flex-1 md:gap-1">
                      <TextInput
                        id="givenName"
                        name="givenName"
                        data-testid="givenName-input"
                        placeholder={t('signUp:input.placeholder.givenName')}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.givenName}
                      />
                      <span className="text-sm text-mars" data-testid="givenName-errors">
                        {errors.givenName && touched.givenName && errors.givenName}
                      </span>
                    </div>
                    <div className="flex flex-col flex-1 md:gap-1">
                      <TextInput
                        id="familyName"
                        name="familyName"
                        data-testid="familyName-input"
                        placeholder={t('signUp:input.placeholder.familyName')}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.familyName}
                      />
                      <span className="text-sm text-mars" data-testid="familyName-errors">
                        {errors.familyName && touched.familyName && errors.familyName}
                      </span>
                    </div>
                  </div>
                  <div className="flex flex-col">
                    <TextInput
                      id="email"
                      name="email"
                      data-testid="email-input"
                      type="email"
                      placeholder={t('signUp:input.placeholder.email')}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.email}
                    />
                    <span className="text-sm text-mars" data-testid="email-errors">
                      {errors.email && touched.email && errors.email}
                    </span>
                  </div>
                  <div className="flex flex-col">
                    <PasswordInput
                      id="password"
                      name="password"
                      placeholder={t('signUp:input.placeholder.password')}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.password}
                    />
                    <span className="text-sm text-mars" data-testid="password-errors">
                      {errors.password && touched.password && errors.password}
                    </span>
                  </div>
                  <div className="flex flex-col gap-2 md:gap-0">
                    <Select
                      id="country"
                      name="country"
                      options={countryOptions}
                      placeholder={t('signUp:input.placeholder.country')}
                      onChange={(option) => {
                        setFieldValue('country', option?.value);
                      }}
                    />
                    <span className="text-sm text-mars" data-testid="country-errors">
                      {errors.country && touched.country && errors.country}
                    </span>
                  </div>
                  <Button type="submit" data-testid="signup-submit-button">
                    {t('signUp:button.createAccount')}
                  </Button>
                </form>
              );
            }}
          </Formik>
        </div>
        {/* <div className="flex flex-row w-full gap-4 items-center h-6">
          <div className="border-solid flex-grow bg-galaxy-300 h-[0.1rem]" />
          <div className="font-medium text-base text-galaxy-500">{t('common:or')}</div>
          <div className="border-solid flex-grow bg-galaxy-300 h-[0.1rem]" />
        </div>
        <GoogleSignUpButton text={t('signUp:button.googleSignUp')} onClick={() => googleSignUp()} /> */}
      </div>
    </AccountCreationLayout>
  );
}
