import { configureStore } from '@reduxjs/toolkit';
import accountSlice from 'app/slices/account/accountSlice';
import toastSlice from 'app/slices/toast/toastSlice';

const store = configureStore({
  reducer: {
    toasts: toastSlice,
    account: accountSlice
  }
});

export default store;

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export type AppStore = typeof store;
