import { IconEye } from 'icons';
import React, { HTMLProps, useState } from 'react';
import TextInput from './TextInput';

export interface PasswordInputProps extends HTMLProps<HTMLInputElement> {
  error?: string;
}

export default function PasswordInput(props: PasswordInputProps) {
  const { error } = props;

  const [showPassword, setShowPassword] = useState(false);

  const handleSetShowPassword = () => {
    setShowPassword((prevState) => !prevState);
  };

  return (
    <TextInput
      {...props}
      error={error}
      type={showPassword ? 'text' : 'password'}
      data-testid="password-input"
      icon={
        <IconEye
          className="text-galaxy-500 cursor-pointer"
          data-testid="password-eye-icon"
          onClick={handleSetShowPassword}
        />
      }
    />
  );
}
