import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { AccountCreationLayout } from 'layouts';
import { useDispatch, useSelector } from 'react-redux';
import { selectAccount } from 'app/slices/account/accountSlice';
import { resendInvitationEmail } from 'lib/api/account';
import { addToast } from 'app/slices/toast/toastSlice';
import cuid from 'cuid';

export default function ThankYou() {
  const { t } = useTranslation(['thankyou', 'common']);
  const dispatch = useDispatch();

  const accountData = useSelector(selectAccount);

  const handleResendInvitationEmail = async (email: string) => {
    try {
      const response = await resendInvitationEmail(email);

      if (response?.status === 200) {
        dispatch(
          addToast({
            type: 'success',
            message: t('thankyou:toast.resendInvitationEmail.success'),
            id: cuid()
          })
        );
      } else {
        // TODO: 11/15/22 update error messages from API when we have them updated
        dispatch(addToast({ type: 'error', message: response?.data.error, id: cuid() }));
      }
    } catch (error) {
      dispatch(addToast({ type: 'error', message: t('common:errors.generic.server'), id: cuid() }));
    }
  };
  return (
    <AccountCreationLayout>
      <div className="flex flex-col gap-5 w-full">
        <div className="flex flex-col gap-2">
          <h1
            className="text-xl md:text-2xl text-galaxy-800 text-center font-bold"
            data-testid="thank-you-heading"
          >
            {t('thankYou:heading')}
          </h1>
        </div>
        <div className="flex flex-col gap-10">
          <p
            className="text-base md:text-xl text-galaxy-500 text-center font-medium"
            data-testid="thank-you-subheading"
          >
            <Trans
              i18nKey={t('thankYou:subHeading')}
              values={{
                email: accountData.email
              }}
              components={{
                span: <span className="font-semibold text-galaxy-800" />
              }}
            />
          </p>
          <p className="text-base md:text-xl text-galaxy-500 text-center">
            <Trans
              i18nKey={t('thankYou:disclaimer.primary')}
              components={{
                span: (
                  <span
                    data-testid="resend-invitation-email-link"
                    className="text-meteor underline cursor-pointer"
                    onClick={async () => await handleResendInvitationEmail(accountData.email)}
                  />
                )
              }}
            />
          </p>
        </div>
      </div>
    </AccountCreationLayout>
  );
}
