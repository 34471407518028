import React, { HTMLProps } from 'react';

interface ButtonProps extends HTMLProps<HTMLButtonElement> {
  type?: 'submit' | 'button' | 'reset';
}

export default function Button(props: ButtonProps) {
  const { type = 'button' } = props;

  return (
    <button
      {...props}
      type={type}
      className="w-full h-12 text-white font-semibold bg-meteor rounded-lg outline-none disabled:text-galaxy-400 disabled:bg-galaxy-100"
    />
  );
}
