import React from 'react';
import { IconSpinner } from 'icons';

export default function Loading() {
  return (
    <div
      className="w-full h-full flex justify-center items-center"
      data-testid="loading-page-wrapper"
    >
      <IconSpinner className="w-10 h-10 text-galaxy-300" data-testid="spinner-icon" />
    </div>
  );
}
