export default {
  getURLs(): {
    apiServer: string;
    signInUrl: string;
  } {
    const endPoint = window.location.hostname;
    let apiServer;
    let signInUrl;
    // TODO: set up API endpoints on the backend for sandbox and dev envs to our conditional below
    // 12/9/22 Currently API not wired up for sandbox and dev
    switch (endPoint) {
      case 'sign-up.uat.wisesys.info':
        apiServer = 'https://uat.sign-up.wisesys.info' as string;
        signInUrl = 'https://admin.uat.wisesys.info' as string;
        break;
      case 'sign-up.stage.wisesys.info':
        apiServer = 'https://stage.sign-up.wisesys.info' as string;
        signInUrl = 'https://admin.stage.wisesys.info' as string;
        break;
      case 'sign-up.sandbox.wisesys.info':
        apiServer = 'https://sandbox.sign-up.wisesys.info' as string;
        signInUrl = 'https://admin.sandbox.wisesys.info' as string;
        break;

      default:
        // 12/9/22 Set dev API and sign in endpoint to stage because we don't currently support dev
        if (endPoint.match(/.*\.wisesys\.info/) ?? endPoint.match(/^wisesystems\.gitlab\.io\/.*/)) {
          apiServer = 'https://stage.sign-up.wisesys.info' as string;
        } else {
          apiServer = process.env.REACT_APP_API_BASE_URL as string;
        }
        signInUrl = 'https://admin.stage.wisesys.info' as string;
        break;
    }

    return {
      apiServer,
      signInUrl
    };
  }
};
