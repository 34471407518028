import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from 'app/store';

interface Toast {
  id: string;
  type?: string;
  message: string;
}

// Define a type for the slice state
export interface InitialToastState {
  toasts: Toast[];
}

// Define the initial state using that type
export const initialState: InitialToastState = {
  toasts: []
};

export const toastSlice = createSlice({
  name: 'toast',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    addToast: (state, action: PayloadAction<Toast>) => {
      const newToast = { ...action.payload };
      state.toasts.push(newToast);
    },
    removeToast: (state, action: PayloadAction<{ id: string }>) => {
      state.toasts = state.toasts.filter((toast) => toast.id !== action.payload.id);
    }
  }
});

export const { addToast, removeToast } = toastSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const toastList = (state: RootState) => state.toasts;

export default toastSlice.reducer;
