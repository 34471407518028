import { removeToast, toastList } from 'app/slices/toast/toastSlice';
import { Footer, Header } from 'components';
import { IconCancel, IconCheckFill, IconCloseFill, Logo } from 'icons';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { motion } from 'framer-motion';
import signUpServers from 'servers/sign-up-servers';
import { useLocation } from 'react-router-dom';

interface AccountCreationLayoutProps {
  children: React.ReactNode;
}

export default function AccountCreationLayout({ children }: AccountCreationLayoutProps) {
  const { toasts } = useSelector(toastList);

  const location = useLocation();

  const dispatch = useDispatch();

  const handleRemoveToast = (id: string) => {
    dispatch(removeToast({ id }));
  };

  const shouldDisplayMemberSignInButton = /get-started$|thank-you$/.test(location.pathname);

  return (
    <div className="w-full h-full relative">
      <div className="absolute inset-0 z-10 bg-map bg-no-repeat bg-center" />
      <div className="absolute inset-0 z-20 bg-meteor/25" />
      <div className="absolute inset-0 z-50 flex justify-center items-center">
        {Boolean(toasts.length) && (
          <div className="absolute top-0 left-0 right-0 pt-8 flex justify-center z-50">
            <div className="max-w-sm px-4 md:px-0 w-full flex flex-col gap-4">
              {toasts.slice(0, 3).map((toast) => {
                const toastTestId = toast.type ? `toast-${toast.type}` : 'toast';

                return (
                  <motion.div
                    key={toast.id}
                    data-testid={toastTestId}
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    className="px-4 py-2 w-full gap-2 flex items-center justify-between bg-galaxy-800/80 rounded-lg"
                  >
                    {toast.type === 'success' ? (
                      <IconCheckFill
                        className="w-5 h-5 text-earth"
                        data-testid="toast-success-icon"
                      />
                    ) : toast.type === 'error' ? (
                      <IconCloseFill className="w-5 h-5 text-mars" data-testid="toast-error-icon" />
                    ) : null}
                    <div className="flex-1">
                      <span className="text-sm text-white">{toast.message}</span>
                    </div>
                    <button
                      type="button"
                      onClick={() => handleRemoveToast(toast.id)}
                      data-testid="toast-remove-button"
                    >
                      <IconCancel className="w-5 h-5 text-white cursor-pointer" />
                    </button>
                  </motion.div>
                );
              })}
            </div>
          </div>
        )}
        <main className="overflow-auto hide-scrollbar px-4 md:m-4 w-full md:max-h-lg max-w-5xl h-full flex flex-col items-center justify-between bg-white md:rounded-lg md:border-t-20 border-solid border-meteor">
          <Header>
            <Logo className="self-start h-7 py-1 w-auto" data-testid="wise-logo" />
            {shouldDisplayMemberSignInButton && (
              <a href={signUpServers.getURLs().signInUrl}>
                <span className="text-sm md:text-base text-galaxy-500 font-normal">
                  Already a member? <span className="text-sky font-normal">Sign In</span>
                </span>
              </a>
            )}
          </Header>
          <div className="max-w-lg w-full">{children}</div>
          <Footer />
        </main>
      </div>
    </div>
  );
}
